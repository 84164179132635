import React from 'react';
// Importing components
import Banner from './components/Banner';
import Header from './components/Header';
import Nav from './components/Nav';
import About from './components/About';
import Services from './components/Services';
import Work from './components/Work';
import Contact from './components/Contact';

// Main App component
const App = () => {
  return (
    // Main container with background styles applied
    <div className='bg-site bg-no-repeat bg-cover overflow-hidden'>
      {/* Header component */}
      <Header />
      {/* Banner component */}
      <Banner />
      {/* Navigation component */}
      <Nav />
      {/* About section component */}
      <About />
      {/* Services section component */}
      <Services />
      {/* Work section component */}
      <Work />
      {/* Contact section component */}
      <Contact />
      {/* Spacer div to ensure enough scroll space for testing */}
      {/* <div className='h-[4000px]'></div> */}
    </div>
  );
};

export default App;
