// Importing React and ReactDOM libraries
import React from 'react';
import ReactDOM from 'react-dom/client';
// Importing the main CSS file for global styles
import './index.css';
// Importing the main App component
import App from './App';

// Creating a root for rendering the React application
const root = ReactDOM.createRoot(document.getElementById('root'));

// Rendering the App component inside the root element with React StrictMode enabled
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);
