import React from 'react';
// Import CountUp for number animation
import CountUp from 'react-countup';
// Import useInView hook to detect if an element is in view
import { useInView } from 'react-intersection-observer';
// Import motion from framer-motion for animations
import { motion } from 'framer-motion';
// Import custom fadeIn animation variant
import { fadeIn } from '../variants';

const About = () => {
  // Set up the intersection observer hook with a threshold of 0.5
  const [ref, inView] = useInView({
    threshold: 0.5,
  });

  return (
    <section className='section' id='about' ref={ref}>
      <div className='container mx-auto'>
        <div className='flex flex-col gap-y-10 lg:flex-row lg:items-center lg:gap-x-20 lg:gap-y-0 h-screen'>
          {/* Image section with animation */}
          <motion.div
            variants={fadeIn('right', 0.3)}
            initial='hidden'
            whileInView={'show'}
            viewport={{ once: false, amount: 0.3 }}
            className='flex-1 bg-about bg-contain bg-no-repeat h-[390px] mix-blend-lighten bg-top'
          ></motion.div>
          {/* Text section with animation */}
          <motion.div
            variants={fadeIn('left', 0.5)}
            initial='hidden'
            whileInView={'show'}
            viewport={{ once: false, amount: 0.3 }}
            className='flex-1'
          >
            <h2 className='h2 text-accent'>About me.</h2>
            <h3 className='h3 mb-4'>
              As a freelance video editor with over 5 years of experience, I'm Zam, a 20-year-old from Palestine.
            </h3>
            <p className='mb-6'>
              Video editing isn't just a job for me; it's my lifelong passion that I've refined since a young age.
              Driven by unwavering passion, motivation, and a commitment to excellence in every project!
            </p>
            {/* Stats section */}
            <div className='flex gap-x-6 lg:gap-x-10 mb-12'>
              {/* Years of Experience */}
              <div>
                <div className='text-[40px] font-tertiary text-gradient mb-2'>
                  {inView ? <CountUp start={0} end={6} duration={3} /> : null}
                </div>
                <div className='font-primary text-sm tracking-[2px]'>
                  Years of <br />
                  Experience
                </div>
              </div>
              {/* Projects Completed */}
              <div>
                <div className='text-[40px] font-tertiary text-gradient mb-2'>
                  {inView ? <CountUp start={0} end={10} duration={3} /> : null}
                  M+
                </div>
                <div className='font-primary text-sm tracking-[2px]'>
                  Views Under<br />
                  Videos Completed
                </div>
              </div>
              {/* Satisfied Clients */}
              <div>
                <div className='text-[40px] font-tertiary text-gradient mb-2'>
                  {inView ? <CountUp start={10} end={25} duration={3} /> : null}
                  +
                </div>
                <div className='font-primary text-sm tracking-[2px]'>
                  Satisfied <br />
                  Clients
                </div>
              </div>
            </div>
            {/* Contact me button */}
            <div className='flex gap-x-8 items-center'>
              <a href='#contact'>
                <button className='btn btn-lg'>Contact me</button>
              </a>
            </div>
          </motion.div>
        </div>
      </div>
    </section>
  );
};

export default About;
