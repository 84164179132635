import React from 'react';
// Import icon from 'react-icons' library
import { BsArrowUpRight } from 'react-icons/bs';
// Import motion for animations
import { motion } from 'framer-motion';
// Import fadeIn variant for animations
import { fadeIn } from '../variants';

// Array containing services data
const services = [
  {
    name: 'Premiere Pro (Adobe):',
    description:
      'Imagine having a video editor who can turn your raw footage into polished masterpieces that keep your viewers glued to the screen. With Premiere Pro skills, your videos will flow seamlessly, transitions will be smooth, and the overall editing will make your content shine.',
    link: <a href='https://en.wikipedia.org/wiki/Adobe_Premiere_Pro'>Learn more</a>,
  },
  {
    name: 'After Effects (Adobe):',
    description:
      'Picture having a video editor who can sprinkle magic on your videos with stunning effects, engaging animations, and eye-catching graphics. With After Effects expertise, your videos will have that wow factor that captivates your audience and sets your channel apart.',
    link: <a href='https://en.wikipedia.org/wiki/Adobe_After_Effects'>Learn more</a>,
  },
  {
    name: 'FL Studio:',
    description:
      'Envision having a music maestro who can craft custom soundtracks, catchy beats, and mood-setting tunes for your videos. With FL Studio proficiency, your content will have the perfect audio accompaniment that enhances the viewing experience and keeps your viewers coming back for more.',
    link: <a href='https://en.wikipedia.org/wiki/FL_Studio'>Learn more</a>,
  },
  {
    name: 'Photoshop (Adobe):',
    description:
      'Visualize having a creative genius who can design captivating thumbnails, striking channel art, and attention-grabbing graphics for your videos. With Photoshop skills, your channel will have a visually appealing identity that attracts viewers and reflects your unique style as a content creator.',
    link: <a href='https://en.wikipedia.org/wiki/Adobe_Photoshop'>Learn more</a>,
  },
];

const Services = () => {
  return (
    // Section for services with id 'services'
    <section className='section' id='services'>
      <div className='container mx-auto'>
        <div className='flex flex-col lg:flex-row'>
          {/* Text & image container */}
          <motion.div
            variants={fadeIn('right', 0.3)} // Animation variant for fade-in from the right
            initial='hidden' // Initial animation state
            whileInView={'show'} // Animation state when in view
            viewport={{ once: false, amount: 0.3 }} // Animation trigger settings
            className='flex-1 lg:bg-services lg:bg-bottom bg-no-repeat mix-blend-lighten mb-12 lg:mb-0'
          >
            <h2 className='h2 text-accent mb-6'>What I Do.</h2>
            <h3 className='h3 max-w-[455px] mb-16'>
              ٩(＾◡＾)۶
            </h3>
            <a href='https://ytjobs.co/talent/vitrine/48131'>
              <button className='btn btn-sm'>See my work</button>
            </a>
          </motion.div>
          {/* Services list container */}
          <motion.div
            variants={fadeIn('left', 0.5)} // Animation variant for fade-in from the left
            initial='hidden' // Initial animation state
            whileInView={'show'} // Animation state when in view
            viewport={{ once: false, amount: 0.3 }} // Animation trigger settings
            className='flex-1'
          >
            <div>
              {services.map((service, index) => {
                // Destructure service object
                const { name, description, link } = service;
                return (
                  // Each service item
                  <div
                    className='border-b border-white/20 h-[180px] mb-[18px] flex'
                    key={index}
                  >
                    <div className='max-w-[476px]'>
                      <h4 className='text-[20px] tracking-wider font-primary font-semibold mb-6'>
                        {name}
                      </h4>
                      <p className='font-secondary leading-tight'>
                        {description}
                      </p>
                    </div>
                    <div className='flex flex-col flex-1 items-end'>
                      
                      <a href='#' className='text-gradient text-sm'>
                        {link}
                      </a>
                    </div>
                  </div>
                );
              })}
            </div>
          </motion.div>
        </div>
      </div>
    </section>
  );
};

export default Services;
