// Exporting the fadeIn function which takes direction and delay as parameters
export const fadeIn = (direction, delay) => {
  return {
    // Initial state for the animation when the element is hidden
    hidden: {
      // If direction is 'up', move element down by 80px, if 'down', move it up by 80px, else no vertical movement
      y: direction === 'up' ? 80 : direction === 'down' ? -80 : 0,
      // Start with zero opacity (completely transparent)
      opacity: 0,
      // If direction is 'left', move element right by 80px, if 'right', move it left by 80px, else no horizontal movement
      x: direction === 'left' ? 80 : direction === 'right' ? -80 : 0,
    },
    // Final state for the animation when the element is fully shown
    show: {
      // No vertical displacement
      y: 0,
      // No horizontal displacement
      x: 0,
      // Fully opaque
      opacity: 1,
      // Transition settings for the animation
      transition: {
        // Type of animation (tween is a type of interpolation for smooth transitions)
        type: 'tween',
        // Duration of the animation in seconds
        duration: 1.2,
        // Delay before the animation starts
        delay: delay,
        // Easing function to control the animation's rate of change
        ease: [0.25, 0.25, 0.25, 0.75],
      },
    },
  };
};
