import React from 'react';
// Import the logo image
import Logo from '../assets/logo.png';

const Header = () => {
  return (
    <header className='py-8'>
      {/* Main container for the header content */}
      <div className='container mx-auto'>
        {/* Flex container to align items horizontally and space them out */}
        <div className='flex justify-between items-center'>
          {/* Logo section */}
          <a href='#'>
            {/* Display the logo image */}
            <img src={Logo} alt='Logo' />
          </a>
          {/* Button to prompt users to work with you */}
          <a href='https://discordid.netlify.app/?id=401769252006395905'>
            <button className='btn btn-sm'>Work with me</button>
          </a>
        </div>
      </div>
    </header>
  );
};

export default Header;
