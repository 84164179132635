// Import necessary React hooks and dependencies
import React, { useRef, useState, useEffect } from 'react';
import './work.css'; // Import the CSS file for styling

// Import framer-motion for animations
import { motion } from 'framer-motion';
// Import animation variants
import { fadeIn } from '../variants';

// Import video files and overlay images
import video1 from '../assets/itsZam-Is_this_game_going_to_kill_CSGO.mp4';
import video2 from '../assets/Lucid-Video_Game_Piracy_is_So_GOOD.mp4';
import video3 from '../assets/itsZam-t1.mp4';
import overlayImage1 from '../assets/blepp.png';
import overlayImage2 from '../assets/lucid.jpg';
import overlayImage3 from '../assets/zamm.png';

const Work = () => {
  // Create references for each video element
  const videoRef1 = useRef(null);
  const videoRef2 = useRef(null);
  const videoRef3 = useRef(null);

  // State to control the visibility of overlay images for each video
  const [showOverlay1, setShowOverlay1] = useState(true);
  const [showOverlay2, setShowOverlay2] = useState(true);
  const [showOverlay3, setShowOverlay3] = useState(true);

  // Ref to store pause timers for each video
  const pauseTimers = useRef({});

  // Function to handle video click events
  const handleVideoClick = (videoRef, setShowOverlay, overlayKey) => {
    if (videoRef.current.paused) {
      // Play the video if it is paused and hide the overlay
      videoRef.current.play();
      setShowOverlay(false);
      // Clear any existing timer for the overlay
      if (pauseTimers.current[overlayKey]) {
        clearTimeout(pauseTimers.current[overlayKey]);
        pauseTimers.current[overlayKey] = null;
      }
    } else {
      // Pause the video if it is playing and set a timer to show the overlay after 10 seconds
      videoRef.current.pause();
      pauseTimers.current[overlayKey] = setTimeout(() => {
        setShowOverlay(true);
      }, 10000);
    }
  };

  // useEffect to clean up timers when the component unmounts
  useEffect(() => {
    return () => {
      // Clear all timers on component unmount to avoid memory leaks
      Object.values(pauseTimers.current).forEach(timer => clearTimeout(timer));
    };
  }, []);

  return (
    <section className='section' id='work'>
      <div className='container mx-auto'>
        <div className='flex flex-col lg:flex-row gap-x-10'>
          {/* First column with motion animation */}
          <motion.div
            variants={fadeIn('right', 0.3)} // Animation variant
            initial='hidden'
            whileInView={'show'}
            viewport={{ once: false, amount: 0.3 }}
            className='flex-1 flex flex-col gap-y-12 mb-10 lg:mb-0'
          >
            <div>
              <h2 className='h2 leading-tight text-accent'>
                My Latest <br />
                Work.
              </h2>
              <p className='max-w-sm mb-16'>
              Here's a showcase of some of my top projects. Feel free to explore them! To view my full portfolio with all my videos, just click the button below !
              </p>
              <a href='https://ytjobs.co/talent/vitrine/48131'>
                <button className='btn btn-sm'>View all projects ᕙ(⇀‸↼‶)ᕗ</button>
              </a>
            </div>
            <div
              className='group relative overflow-hidden border-2 border-white/50 rounded-xl'
              onClick={() => handleVideoClick(videoRef1, setShowOverlay1, 'overlay1')}
            >
              {/* Conditional rendering of overlay image */}
              {showOverlay1 && (
                <img
                  className='absolute w-full h-full object-cover z-30'
                  src={overlayImage1}
                  alt='Overlay'
                />
              )}
              <div className='group-hover:bg-black/70 w-full h-full absolute z-40 transition-all duration-300 flex items-center justify-center'>
                <div className='play-button hidden group-hover:block'>
                  ►
                </div>
              </div>
              <video
                ref={videoRef1}
                className='group-hover:scale-125 transition-all duration-500 w-full'
                src={video1}
                controls={false}
              />
              <div className='absolute bottom-full left-12 group-hover:bottom-24 transition-all duration-500 z-50'>
                <span className='text-gradient'>Is this game going to kill CSGO? - Bad Steam Games</span>
              </div>
              <div className='absolute bottom-full left-12 group-hover:bottom-14 transition-all duration-700 z-50'>
                <span className='text-3xl text-white'>itsZam</span>
              </div>
            </div>
          </motion.div>
          {/* Second column with motion animation */}
          <motion.div
            variants={fadeIn('left', 0.2)} // Animation variant
            initial='hidden'
            whileInView={'show'}
            viewport={{ once: false, amount: 0.3 }}
            className='flex-1 flex flex-col gap-y-10'
          >
            <div
              className='group relative overflow-hidden border-2 border-white/50 rounded-xl'
              onClick={() => handleVideoClick(videoRef2, setShowOverlay2, 'overlay2')}
            >
              {/* Conditional rendering of overlay image */}
              {showOverlay2 && (
                <img
                  className='absolute w-full h-full object-cover z-30'
                  src={overlayImage2}
                  alt='Overlay'
                />
              )}
              <div className='group-hover:bg-black/70 w-full h-full absolute z-40 transition-all duration-300 flex items-center justify-center'>
                <div className='play-button hidden group-hover:block'>
                  ►
                </div>
              </div>
              <video
                ref={videoRef2}
                className='group-hover:scale-125 transition-all duration-500 w-full'
                src={video2}
                controls={false}
              />
              <div className='absolute bottom-full left-12 group-hover:bottom-24 transition-all duration-500 z-50'>
                <span className='text-gradient'>Video Game Piracy is So GOOD</span>
              </div>
              <div className='absolute bottom-full left-12 group-hover:bottom-14 transition-all duration-700 z-50'>
                <span className='text-3xl text-white'>iamLucid</span>
              </div>
            </div>
            <div
              className='group relative overflow-hidden border-2 border-white/50 rounded-xl'
              onClick={() => handleVideoClick(videoRef3, setShowOverlay3, 'overlay3')}
            >
              {/* Conditional rendering of overlay image */}
              {showOverlay3 && (
                <img
                  className='absolute w-full h-full object-cover z-30'
                  src={overlayImage3}
                  alt='Overlay'
                />
              )}
              <div className='group-hover:bg-black/70 w-full h-full absolute z-40 transition-all duration-300 flex items-center justify-center'>
                <div className='play-button hidden group-hover:block'>
                  ►
                </div>
              </div>
              <video
                ref={videoRef3}
                className='group-hover:scale-125 transition-all duration-500 w-full'
                src={video3}
                controls={false}
              />
              <div className='absolute bottom-full left-12 group-hover:bottom-24 transition-all duration-500 z-50'>
                <span className='text-gradient'>t1</span>
              </div>
              <div className='absolute bottom-full left-12 group-hover:bottom-14 transition-all duration-700 z-50'>
                <span className='text-3xl text-white'>itsZam</span>
              </div>
            </div>
          </motion.div>
        </div>
      </div>
    </section>
  );
};

export default Work;
