import React, { useRef, useState } from 'react';
// Import motion for animations from framer-motion
import { motion } from 'framer-motion';
// Import custom fadeIn animation variant
import { fadeIn } from '../variants';
// Import EmailJS for sending emails
import emailjs from '@emailjs/browser';

const Contact = () => {
  // Create a reference for the form element
  const form = useRef();
  // State to handle feedback message after form submission
  const [feedbackMessage, setFeedbackMessage] = useState('');
  // State to handle success or failure of the email sending
  const [isSuccess, setIsSuccess] = useState(false);

  // Function to handle email sending via EmailJS
  const sendEmail = (e) => {
    e.preventDefault(); // Prevent the default form submission behavior

    // Use EmailJS to send the form data
    emailjs
      .sendForm('service_662ou5y', 'template_ve4uel8', form.current, 'rZt2csZByyXDUMo_9')
      .then(
        () => {
          // On success, set success state and display success message
          setIsSuccess(true);
          setFeedbackMessage('Message sent successfully!');
          form.current.reset(); // Reset the form fields
        },
        (error) => {
          // On failure, set failure state and display error message
          setIsSuccess(false);
          setFeedbackMessage(`Failed to send message: ${error.text}`);
        }
      );
  };

  return (
    <section className='py-16 lg:section' id='contact'>
      <div className='container mx-auto'>
        <div className='flex flex-col lg:flex-row'>
          {/* Text section with animation */}
          <motion.div
            variants={fadeIn('right', 0.3)} // Apply fadeIn animation from the right
            initial='hidden' // Initial state of the animation
            whileInView={'show'} // Animate when the element is in view
            viewport={{ once: false, amount: 0.3 }} // Viewport settings for the animation
            className='flex-1 flex justify-start items-center'
          >
            <div>
              <h4 className='text-xl uppercase text-accent font-medium mb-2 tracking-wide'>
                Get in touch
              </h4>
              <h2 className='text-[45px] lg:text-[90px] leading-none mb-12'>
                Let's work <br /> together!
              </h2>
            </div>
          </motion.div>
          {/* Form section with animation */}
          <motion.form
            ref={form} // Attach the form reference
            onSubmit={sendEmail} // Handle form submission
            variants={fadeIn('left', 0.3)} // Apply fadeIn animation from the left
            initial='hidden' // Initial state of the animation
            whileInView={'show'} // Animate when the element is in view
            viewport={{ once: false, amount: 0.3 }} // Viewport settings for the animation
            className='flex-1 border rounded-2xl flex flex-col gap-y-6 pb-24 p-6 items-start'
          >
            <input
              className='bg-transparent border-b py-3 outline-none w-full placeholder:text-white focus:border-accent transition-all'
              type='text'
              name='user_name'
              placeholder='Your name'
            />
            <input
              className='bg-transparent border-b py-3 outline-none w-full placeholder:text-white focus:border-accent transition-all'
              type='email'
              name='user_email'
              placeholder='Your email'
            />
            <textarea
              className='bg-transparent border-b py-12 outline-none w-full placeholder:text-white focus:border-accent transition-all resize-none mb-12'
              name='message'
              placeholder='Your message'
            ></textarea>
            <button className='btn btn-lg' type='submit'>Send message</button>
            {feedbackMessage && (
              <p className={`mt-4 ${isSuccess ? 'text-green-500' : 'text-red-500'}`}>
                {feedbackMessage}
              </p>
            )}
          </motion.form>
        </div>
      </div>
    </section>
  );
};

export default Contact;
